import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // 确保路径正确

const ProtectedRoute = () => {
  const { user } = useAuth();

  if (!user) {
    // 用户未登录，重定向到登录页面
    return <Navigate to="/login" replace />;
  }

  // 用户已登录，渲染子路由
  return <Outlet />;
};

export default ProtectedRoute;