import React from 'react';
import { Card } from 'antd';
import { useAuth } from '../contexts/AuthContext';

const Profile = () => {
  const { user } = useAuth();

  return (
    <Card title="User Profile">
      <p><strong>Role:</strong> {user.role}</p>
      <p><strong>Account:</strong> {user.account}</p>
      {/* Add more user information as needed */}
    </Card>
  );
};

export default Profile;