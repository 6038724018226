import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { AuthProvider } from './contexts/AuthContext';
import Login from './Auth/Login';
import MainLayout from './Common/MainLayout';
import Profile from './Common/Profile';
import Orders from './Enterprise/Orders';
import ProductManagement from './Supplier/Products';
import SupplierDashboard from './Pages/SupplierDashboard';
import EnterpriseDashboard from './Pages/EnterpriseDashboard';
import QCDashboard from './Pages/QCDashboard';
import FinanceDashboard from './Pages/FinanceDashboard';
import Header from './Common/Header';
import Footer from './Common/Footer';
import ProtectedRoute from './Auth/ProtectedRoute';
import ProductBrowse from './Enterprise/ProductBrowse';
import QualityControl from './QC/QualityControl';
function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Profile />} />
              <Route path="profile" element={<Profile />} />
              <Route path="orders" element={<Orders />} />
              <Route path="products" element={<ProductManagement />} />
              <Route path="productBrowse" element={<ProductBrowse />} />
              <Route path="quality-control" element={<QualityControl />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;