import { LeoWalletAdapter} from "@demox-labs/aleo-wallet-adapter-leo"
import { WalletAdapterNetwork,DecryptPermission,Transaction } from '@demox-labs/aleo-wallet-adapter-base';

let adapeter = new LeoWalletAdapter();
// let  adapeter  = new LeoWalletAdapter({
//     network: WalletAdapterNetwork.TestnetBeta,
//     decryptPermission: DecryptPermission.ANYONE,
// });

export const programId = 'zsupplierversion03.aleo'
// connect to the wallet
export const connectWallet = async () => {
    try {
        // Connect to the wallet using the Leo adapter
      await adapeter.connect(DecryptPermission.AutoDecrypt,WalletAdapterNetwork.TestnetBeta);
      return true;
    } catch (error) {
      console.error('Failed to connect wallet:', error);
      throw error;
    }
  };

// sign messages
export const signMessage =  async (message)=>{
    try {
        await connectWallet();
        const bytes = new TextEncoder().encode(message);  
        const signature = await adapeter.signMessage(bytes);

        const decoder = new TextDecoder('utf-8');
        const decodedString = decoder.decode(signature);
        return decodedString;
    } catch (error) {
        console.error('Failed to sign message:', error);
        throw error;
    }
  };

  // sign transaction
  export const signTransaction = async (tx) => {
    try {
        const signature = await adapeter.signTransaction(tx);
        return signature;
    } catch (error) {
        console.error('Failed to sign transaction:', error);
        throw error;
    }
  };

  // request records from porgram
  export const requestRecords = async () => {
    try {
        await connectWallet();
        const records = await adapeter.requestRecords(programId);
        return records;
    } catch (error) {
        console.error('Failed to request records:', error);
        connectWallet();
        throw error;
    }
  };

  // request execute transaction
  export const requestExecuteTransaction = async (inputs,method,fee,privateFee = false) => {
    try {
        await connectWallet();
        // create Transaction
        const transaction = Transaction.createTransaction(
            adapeter.publicKey,
            WalletAdapterNetwork.TestnetBeta,
            programId,
            method,
            inputs,
            fee,
            privateFee
        );

        const transactionId = await adapeter.requestTransaction(transaction);
        return transactionId;
    } catch (error) {
        console.error('Failed to request execute transaction:', error);
        throw error;
    }
  };




  export const string_to_ascii = (input) => {
    let resultString = '';

    for (let i = 0; i < input.length; i++) {
        // 获取字符的 ASCII 值
        const asciiValue = input.charCodeAt(i);
        
        // 加上 100
        const modifiedAsciiValue = asciiValue + 100;
        
        // 将修改后的 ASCII 值拼接为字符串
        resultString += modifiedAsciiValue;
        
        // 如果需要，可以在每个值之间添加分隔符，比如逗号或空格
        // resultString += modifiedAsciiValue + ',';
    }

    return resultString;
}

// ascill to string format
export const  ascii_to_string= (field)=> {
    if(field === '0field.private'){return ''}
    const content = field.replace('field','').replace('.private','').replace('field.private','')
    let intArray = []
    for(let i=0;i<content.length/3;i++){
        intArray.push(content.substr(i*3,3)-100)
    }
    return String.fromCharCode(...intArray)
}

export const rustStruct_to_json = (structStr)=> {

    const regex = /(\w+):\s*(\w+)/g;
  const matches = [...structStr.matchAll(regex)];

  const result = {};
  for (const [_, key, value] of matches) {
    result[key] = isNaN(value) ? value : Number(value);
  }

  return result;
}

