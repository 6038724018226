import React, { useState, useEffect } from 'react';
import { Table, Select, Button, message, Space } from 'antd';
import { CheckOutlined, CloseOutlined, SyncOutlined } from '@ant-design/icons';

const { Option } = Select;

const QualityControl = () => {
  const [inspections, setInspections] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchInspections();
  }, []);

  const fetchInspections = async () => {
    setLoading(true);
    try {
      // 这里应该是实际的 API 调用
      // 为了演示，我们使用模拟数据
      const mockInspections = [
        { id: 1, productName: "Product A", orderNumber: "ORD001", currentStatus: "Pending", quantity: 100 },
        { id: 2, productName: "Product B", orderNumber: "ORD002", currentStatus: "In Progress", quantity: 50 },
        { id: 3, productName: "Product C", orderNumber: "ORD003", currentStatus: "Pending", quantity: 75 },
      ];
      setInspections(mockInspections);
    } catch (error) {
      message.error('Failed to fetch inspections');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (inspectionId, newStatus) => {
    try {
      // 这里应该是实际的 API 调用来更新状态
      // 为了演示，我们直接更新本地状态
      setInspections(inspections.map(inspection => 
        inspection.id === inspectionId ? { ...inspection, currentStatus: newStatus } : inspection
      ));
      message.success('Inspection status updated successfully');
    } catch (error) {
      message.error('Failed to update inspection status');
    }
  };

  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Current Status',
      dataIndex: 'currentStatus',
      key: 'currentStatus',
      render: (status) => {
        let color = 'blue';
        let icon = <SyncOutlined spin />;
        if (status === 'Passed') {
          color = 'green';
          icon = <CheckOutlined />;
        } else if (status === 'Failed') {
          color = 'red';
          icon = <CloseOutlined />;
        }
        return (
          <Space>
            {icon}
            <span style={{ color }}>{status}</span>
          </Space>
        );
      },
    },
    {
      title: 'Update Status',
      key: 'action',
      render: (_, record) => (
        <Select
          defaultValue={record.currentStatus}
          style={{ width: 120 }}
          onChange={(value) => handleStatusChange(record.id, value)}
        >
          <Option value="Pending">Pending</Option>
          <Option value="In Progress">In Progress</Option>
          <Option value="Passed">Passed</Option>
          <Option value="Failed">Failed</Option>
        </Select>
      ),
    },
  ];

  return (
    <div>
      <h2>QC Inspection List</h2>
      <Table
        columns={columns}
        dataSource={inspections}
        rowKey="id"
        loading={loading}
      />
    </div>
  );
};

export default QualityControl;