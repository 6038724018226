import React, { useState, useEffect } from 'react';
import { List, Card, Input, Button, message } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { useAuth } from '../contexts/AuthContext';
import * as walletHelper from '../Common/WalletHelper'

const ProductBrowse = () => {
  const [products, setProducts] = useState([]);
  const [quantities, setQuantities] = useState({});
  const {user} = useAuth();

  useEffect(() => {
    // 假设这是从 API 获取产品列表的函数
    fetchProducts();
  }, []);

  const fetchProducts = async () => {

    const productsMappings = await requstProducts();
    console.log('products',productsMappings)

    const products = []
    productsMappings.forEach(element => {
        const json = walletHelper.rustStruct_to_json(element.value);
        console.log(json);
        const product_id = json.product_id;
        const name = walletHelper.ascii_to_string(json.name);
        const description = walletHelper.ascii_to_string(json.comment);
        const price = parseFloat(json.price.replace('u64',''))/1000.0 
        const supplier_address = json.supplier_address;
        products.push({ product_id: product_id, name: name, description: description, price: price ,supplier_address:supplier_address});
    });
   
    setProducts(products);
  };

  const handleQuantityChange = (productId, quantity) => {
    setQuantities({
      ...quantities,
      [productId]: quantity
    });
  };

  const handleOrder = async (product) => {
    const quantity = quantities[product.id] || 0;
    if (quantity <= 0) {
      message.error('Please enter a valid quantity');
      return;
    }
    try{
        const product_id = product.product_id;
        const price = (parseFloat(product.price)*1000).toString()+'u64'
        const supplier_address = product.supplier_address;
        const qty = quantity+'u64';
        const timestamp = Math.floor(new Date().getTime() / 1000) + 'u32';
        const qc = user.account;
        const default_signature = await walletHelper.signMessage('startTransaction')
    
        const orderData = {
            product_id: product_id,
            price: price,
            supplier_address: supplier_address,
            qty: qty,
            timestamp:timestamp,
            qc: qc,
            default_signature: default_signature,
        };
        const contentCode = JSON.stringify(orderData).replace(/"/g, '')
        await walletHelper.requestExecuteTransaction([contentCode],'order_products',1000000,false);
    
        // 这里应该调用下单 API
        console.log(`Ordering ${quantity} of ${product.name}`);
    }
    catch (error) {
        message.error('Failed to order product: ' + error.message);
    }
    finally{
        // 清除数量输入
    setQuantities({
        ...quantities,
        [product.id]: 0
      });
    }

    
  };

  return (
    <div>
      <h2>Product Catalog</h2>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 4,
          xxl: 4,
        }}
        dataSource={products}
        renderItem={item => (
          <List.Item>
            <Card title={item.name}>
              <p>{item.description}</p>
              <p>Price: ${item.price}</p>
              <Input
                type="number"
                min={0}
                value={quantities[item.id] || ''}
                onChange={(e) => handleQuantityChange(item.id, e.target.value)}
                style={{ width: '100%', marginBottom: '10px' }}
                placeholder="Quantity"
              />
              <Button
                type="primary"
                icon={<ShoppingCartOutlined />}
                onClick={() => handleOrder(item)}
                block
              >
                Order
              </Button>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

const requstProducts = async () => {
    const baseUrl = 'https://testnet.aleoscan.io';
    const productsMapping = '/api/v2/mapping/list_program_mapping_values/' + walletHelper.programId + '/products';

    try {
        const response = await fetch(baseUrl + productsMapping);
        
        // 检查响应是否成功
        if (!response.ok) {
            throw new Error('网络响应错误: ' + response.status);
        }
        
        // 解析 JSON 数据
        const data = await response.json();
        
        // 确保 data.result 存在
        if (data && data.result) {
            return data.result;  // 返回结果数据
        } else {
            throw new Error('结果为空或无效');
        }
    } catch (error) {
        console.error('请求失败: ', error);
        return null; // 如果出错，返回 null
    }
};



export default ProductBrowse;