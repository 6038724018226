import React from 'react';
import { Tabs, Button, Card, message } from 'antd';
import { WalletOutlined } from '@ant-design/icons';
import { useAuth } from '../contexts/AuthContext';
import styles from './LoginPage.module.css';
import { LeoWalletAdapter} from "@demox-labs/aleo-wallet-adapter-leo"
import { WalletAdapterNetwork,DecryptPermission } from '@demox-labs/aleo-wallet-adapter-base';
import { useNavigate } from 'react-router-dom';
const leoWalletAdapeter = new LeoWalletAdapter();
const { TabPane } = Tabs;

const LoginPage = () => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleConnect = async (role) => {
    try {
      await leoWalletAdapeter.connect(DecryptPermission.AutoDecrypt,WalletAdapterNetwork.TestnetBeta);
      login(leoWalletAdapeter.publicKey, role);
      message.success('Successfully connected to LeoWallet');
      navigate('/');
      // Add redirection logic after successful connection here
    } catch (error) {
      message.error('Failed to connect: ' + error.message);
    }
  };

  const ConnectWalletButton = ({ role }) => (
    <Button
      type="primary"
      icon={<WalletOutlined />}
      onClick={() => handleConnect(role)}
      size="large"
      block
    >
      Connect LeoWallet
    </Button>
  );

  return (
    <div className={styles.loginContainer}>
      <Card className={styles.loginCard} title="ZSupply Login">
        <Tabs defaultActiveKey="enterprise">
          <TabPane tab="Enterprise" key="enterprise">
            <ConnectWalletButton role="enterprise" />
          </TabPane>
          <TabPane tab="Supplier" key="supplier">
            <ConnectWalletButton role="supplier" />
          </TabPane>
          <TabPane tab="QC" key="qc">
            <ConnectWalletButton role="qc" />
          </TabPane>
          <TabPane tab="Finance" key="finance">
            <ConnectWalletButton role="finance" />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default LoginPage;