import React, { useState, useEffect } from 'react';
import { Table, Input, Tag, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import * as walletHelper from '../Common/WalletHelper'

const { Search } = Input;

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch orders data from your API
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    // Replace this with actual API call
    setLoading(true);
    try {

      const records = await walletHelper.requestRecords();
      console.log("records: " + records)
      
      // Simulating API call with setTimeout
      setTimeout(() => {
        const mockData = [
          {
            key: '1',
            orderNumber: 'ORD001',
            productName: 'Widget A',
            unitPrice: 10,
            quantity: 100,
            totalPrice: 1000,
            status: 'Ordered',
            qcResult: 'Pending',
            supplier: 'Supplier X',
          },
          // Add more mock data here
        ];
        setOrders(mockData);
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error('Failed to fetch orders:', error);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      sorter: (a, b) => a.orderNumber.localeCompare(b.orderNumber),
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
      sorter: (a, b) => a.productName.localeCompare(b.productName),
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      sorter: (a, b) => a.unitPrice - b.unitPrice,
      render: (price) => `$${price.toFixed(2)}`,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: 'Total Price',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      sorter: (a, b) => a.totalPrice - b.totalPrice,
      render: (price) => `$${price.toFixed(2)}`,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Ordered', value: 'Ordered' },
        { text: 'Completed', value: 'Completed' },
        { text: 'In QC', value: 'In QC' },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (status) => {
        let color = status === 'Completed' ? 'green' : status === 'In QC' ? 'geekblue' : 'volcano';
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'QC Result',
      dataIndex: 'qcResult',
      key: 'qcResult',
      filters: [
        { text: 'Passed', value: 'Passed' },
        { text: 'Failed', value: 'Failed' },
        { text: 'Pending', value: 'Pending' },
      ],
      onFilter: (value, record) => record.qcResult.indexOf(value) === 0,
      render: (result) => {
        let color = result === 'Passed' ? 'green' : result === 'Failed' ? 'red' : 'gold';
        return (
          <Tag color={color} key={result}>
            {result.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      key: 'supplier',
      sorter: (a, b) => a.supplier.localeCompare(b.supplier),
    },
  ];

  const handleSearch = (value) => {
    // Implement search logic here
    console.log('Search value:', value);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Search
        placeholder="Search orders"
        onSearch={handleSearch}
        style={{ width: 200 }}
        enterButton
      />
      <Table
        columns={columns}
        dataSource={orders}
        loading={loading}
        scroll={{ x: 'max-content' }}
      />
    </Space>
  );
};

export default Orders;