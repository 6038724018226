import React, { useState, useEffect } from 'react';
import { Card, List, Image, Typography, Button, Modal, Form, Input, InputNumber,message } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import * as WalletHelper from '../Common/WalletHelper'
import { useAuth } from '../contexts/AuthContext';

const { Title, Paragraph, Text } = Typography;

const ProductManagement = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const {user} = useAuth();

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    // Replace this with actual API call
    setLoading(true);
    try {

        

      // Simulating API call with setTimeout
      setTimeout(() => {
        const mockProducts = [
          {
            id: '1',
            name: 'Product A',
            price: 99.99,
            image: 'https://via.placeholder.com/100',
            description: 'This is a description for Product A.',
          },
          {
            id: '2',
            name: 'Product B',
            price: 149.99,
            image: 'https://via.placeholder.com/100',
            description: 'This is a description for Product B.',
          },
          // Add more mock products here
        ];
        setProducts(mockProducts);
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error('Failed to fetch products:', error);
      setLoading(false);
    }
  };

  const handleEdit = (productId) => {
    // Implement edit functionality
    console.log('Edit product:', productId);
  };

  const handleDelete = (productId) => {
    // Implement delete functionality
    console.log('Delete product:', productId);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleAddProduct =  async (values) => {
    // Implement add product functionality
    console.log('New product:', values);
    setIsModalVisible(true);

    try {

        const product_id = Date.now().toString() + 'field';
        const namefield = WalletHelper.string_to_ascii(values.name)+'field';
        const price = (parseFloat(values.price)*1000).toString()+'u64'
        const description = WalletHelper.string_to_ascii(values.description)+'field';


        const productData = {
            product_id: product_id,
            name: namefield,
            comment: description,
            price: price,
            supplier_address:user.account
        };
        const contentCode = JSON.stringify(productData).replace(/"/g, '')
        console.log("metaData",productData)
        form.resetFields();
        setIsModalVisible(false);
        await WalletHelper.requestExecuteTransaction([contentCode],'create_product',1000000,true);
        

    } catch (error) {
        message.error('Failed to add product: ' + error.message);
    } finally {
        setIsModalVisible(false);
    }
  };
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
        <Title level={2}>Product Management</Title>
        <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
          Add New Product
        </Button>
      </div>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
          xxl: 6,
        }}
        dataSource={products}
        loading={loading}
        renderItem={item => (
          <List.Item>
            <Card
              size="small"
              cover={<Image alt={item.name} src={item.image} style={{ height: 100, objectFit: 'cover' }} />}
              actions={[
                <Button icon={<EditOutlined />} size="small" onClick={() => handleEdit(item.id)}>Edit</Button>,
                <Button icon={<DeleteOutlined />} size="small" danger onClick={() => handleDelete(item.id)}>Delete</Button>
              ]}
              style={{ width: 200 }}
            >
              <Card.Meta
                title={item.name}
                description={
                  <>
                    <Paragraph>
                      <Text strong>Price: </Text>
                      ${item.price.toFixed(2)}
                    </Paragraph>
                    <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
                      {item.description}
                    </Paragraph>
                  </>
                }
              />
            </Card>
          </List.Item>
        )}
      />
      <Modal
        title="Add New Product"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleAddProduct} layout="vertical">
          <Form.Item name="name" label="Product Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="price" label="Price" rules={[{ required: true }]}>
            <InputNumber min={0} step={0.01} precision={2} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="image" label="Image URL" rules={[{ required: false }]}>
                <Input placeholder="https://via.placeholder.com/100" />
            </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" >
              Add Product
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProductManagement;