import React from 'react';
import { Layout, Menu } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  UserOutlined,
  ShoppingCartOutlined,
  CheckCircleOutlined,
  SafetyCertificateOutlined,
  DollarOutlined,
  LogoutOutlined,
  AppstoreOutlined
} from '@ant-design/icons';

const { Header, Content, Sider } = Layout;

const MainLayout = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const menuItems = [
    {
      key: 'profile',
      icon: <UserOutlined />,
      label: 'Profile',
    },
    {
      key: 'orders',
      icon: <ShoppingCartOutlined />,
      label: 'Orders',
    },
    {
      key: 'products',
      icon: <AppstoreOutlined />,
      label: 'Product',
    },
    {
      key: 'productBrowse',
      icon: <AppstoreOutlined />,
      label: 'Products List',
    },

    {
      key: 'quality-control',
      icon: <SafetyCertificateOutlined />,
      label: 'Quality Control',
    },
    {
      key: 'finance',
      icon: <DollarOutlined />,
      label: 'Finance',
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Logout',
      onClick: logout,
    },
  ];

  // Filter menu items based on user role
  const filteredMenuItems = menuItems.filter(item => {
    if (user.role === 'supplier') return ['profile', 'orders', 'products', 'logout'].includes(item.key);
    if (user.role === 'enterprise') return ['profile', 'orders','productBrowse', 'logout'].includes(item.key);
    if (user.role === 'qc') return ['profile', 'quality-control', 'logout'].includes(item.key);
    if (user.role === 'finance') return ['profile', 'finance', 'logout'].includes(item.key);
    return false;
  });

  const handleMenuClick = (e) => {
    if (e.key !== 'logout') {
      navigate(`/${e.key}`);
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider width={200} theme="dark">
      <div style={{
          height: '64px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          fontSize: '20px',
          fontWeight: 'bold',
          background: 'rgba(255, 255, 255, 0.2)',
          margin: '16px',
          borderRadius: '4px',
        }}>
          ZSupplier
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={['profile']}
          style={{ height: '100%', borderRight: 0 }}
          items={filteredMenuItems}
          onClick={handleMenuClick}
        />
      </Sider>
      <Layout>
        <Header style={{ background: '#fff', padding: 0 }} />
        <Content style={{ margin: '24px 16px 0' }}>
          <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;